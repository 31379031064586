import React, {useMemo} from 'react';
import {Row, Col, Card, Badge, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {UniswapPosition} from '../../../../model/crypto/CryptoModels';
import FormatUtils from "../../../../service/utils/FormatUtils";

interface PositionSummaryComponentProps {
    positions: UniswapPosition[];
}

interface SummaryData {
    positionValue: number;
    positionChange: number;
    positionChangePercent: number;
    initialValue: number;
    currentValue: number;
    totalReturn: number;
    totalReturnPercent: number;
    totalFees: number;
    collectedFees: number;
    uncollectedFees: number;
    apr: number;
}

const PositionSummaryComponent: React.FC<PositionSummaryComponentProps> = ({positions}) => {
    // Calculate aggregated values from the filtered positions
    const summaryData: SummaryData = useMemo(() => {
        if (!positions || positions.length === 0) {
            return {
                positionValue: 0,
                positionChange: 0,
                positionChangePercent: 0,
                initialValue: 0,
                currentValue: 0,
                totalReturn: 0,
                totalReturnPercent: 0,
                totalFees: 0,
                collectedFees: 0,
                uncollectedFees: 0,
                apr: 0
            };
        }

        // Initialize aggregation variables
        let initialValue = 0;
        let currentValue = 0;
        let totalFees = 0;
        let collectedFees = 0;
        let uncollectedFees = 0;
        let totalReturn = 0;
        let positionChange = 0;

        // For weighted APR calculation
        let totalWeightedApr = 0;
        let totalWeight = 0;

        // Aggregate data from positions
        positions.forEach(position => {
            // Initial value (from initialLiquidityUSD property)
            initialValue += position.initialLiquidityUSD || 0;

            // Current value (from liquidityUSD property)
            currentValue += position.liquidityUSD || 0;

            // Fee calculations (using the defined fee properties)
            totalFees += position.totalFeesUSD || position.feesTotalUSD || 0;
            collectedFees += position.feesCollectedUSD || 0;
            uncollectedFees += position.uncollectedFeesUSD || 0;

            // Total return calculation (using totalReturnUSD)
            totalReturn += position.totalReturnUSD || 0;

            // Position change (using profitLossUSD)
            positionChange += position.profitLossUSD || 0;

            // Weighted APR calculation
            const positionValue = position.liquidityUSD || 0;
            if (positionValue > 0) {
                const positionApr = position.apr || position.annualizedReturn || 0;
                if (!isNaN(positionApr)) {
                    totalWeightedApr += positionApr * positionValue;
                    totalWeight += positionValue;
                }
            }
        });

        // Calculate percentages
        const positionChangePercent = initialValue > 0 ? (positionChange / initialValue) * 100 : 0;
        const totalReturnPercent = initialValue > 0 ? (totalReturn / initialValue) * 100 : 0;

        // Calculate weighted average APR
        const weightedApr = totalWeight > 0 ? totalWeightedApr / totalWeight : 0;

        return {
            positionValue: currentValue,
            positionChange,
            positionChangePercent,
            initialValue,
            currentValue,
            totalReturn,
            totalReturnPercent,
            totalFees,
            collectedFees,
            uncollectedFees,
            apr: weightedApr
        };
    }, [positions]);


    return (
        <>
            {/* Main summary cards */}
            <Row className="g-2 mb-3">
                <Col md={4}>
                    <Card>
                        <Card.Body className="p-3">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h6 className="mb-0">
                                        Total Return
                                    </h6>
                                    <h3 className={`mb-0 mt-1 ${summaryData.totalReturn >= 0 ? 'text-success' : 'text-danger'}`}>
                                        {FormatUtils.formatCurrency(summaryData.totalReturn)}
                                        <small>({summaryData.totalReturnPercent.toFixed(2)}%)</small>
                                    </h3>
                                    <div className="text-muted small mt-1">
                                        <div>
                                            APR: <span
                                            className={`mx-1 ${summaryData.totalReturn >= 0 ? 'text-success' : 'text-danger'}`}>
                                                {summaryData.apr.toFixed(2)}%
                                            </span>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="apr-tooltip">
                                                        Annual Percentage Rate - Projects your current return rate over a full year.
                                                        This is weighted by position size and can appear much higher than your current
                                                        return percentage, especially if positions are relatively new.
                                                    </Tooltip>
                                                }
                                            >
                                                <span className="ms-1 text-muted" style={{cursor: 'help'}}>
                                                    <i className="bi bi-info-circle-fill"></i>
                                                </span>
                                            </OverlayTrigger>
                                        </div>
                                        <div>(Includes {FormatUtils.formatCurrency(summaryData.totalFees)} in fees)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body className="p-3">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h6 className="mb-0">
                                        Positions Value
                                    </h6>
                                    <h3 className={`mb-0 mt-1 ${summaryData.positionChange >= 0 ? 'text-success' : 'text-danger'}`}>
                                        {FormatUtils.formatCurrency(summaryData.positionChange)}
                                        <small>({summaryData.positionChangePercent.toFixed(2)}%)</small>
                                    </h3>
                                    <div className="text-muted small mt-1">
                                        <div>Initial: {FormatUtils.formatCurrency(summaryData.initialValue)}</div>
                                        <div>Current: {FormatUtils.formatCurrency(summaryData.currentValue)}</div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={4}>
                    <Card>
                        <Card.Body className="p-3">
                            <div className="d-flex align-items-center">
                                <div>
                                    <h6 className="mb-0">
                                        Total Fees
                                    </h6>
                                    <h3 className="mb-0 mt-1">
                                        {FormatUtils.formatCurrency(summaryData.totalFees)}
                                    </h3>
                                    <div className="text-muted small mt-1">
                                        <div>Collected: {FormatUtils.formatCurrency(summaryData.collectedFees)}</div>
                                        <div>Uncollected: {FormatUtils.formatCurrency(summaryData.uncollectedFees)}</div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

        </>
    );
};

export default PositionSummaryComponent;