import React, {useState, useEffect} from 'react';
import {UniswapPosition} from "../../../../model/crypto/CryptoModels";
import {CardBody, Col, Container, Form, Row, Button, ButtonGroup, OverlayTrigger, Tooltip} from "react-bootstrap";
import {FaCircle} from "react-icons/fa6";
import {Box, Slider} from "@mui/material";
import {BsInfoCircle} from "react-icons/bs";


/**
 * Formats numbers with appropriate precision based on size
 */
const formatNumber = (value: number | undefined | null, maxDecimals = 2): string => {
    if (value === undefined || value === null) return '0';

    // For displaying token amounts, use more decimals for small values
    if (value < 0.0001) return value.toExponential(4);
    if (value < 0.01) return value.toFixed(6);
    if (value < 0.1) return value.toFixed(4);
    if (value < 1) return value.toFixed(3);
    if (value < 10) return value.toFixed(2);

    // For larger numbers (prices, etc)
    if (value < 1000) return value.toFixed(Math.min(2, maxDecimals));
    return value.toLocaleString('en-US', {maximumFractionDigits: Math.min(2, maxDecimals)});
};

// Interfaces
interface TokenRatioResult {
    token0Amount: number;
    token1Amount: number;
    ratio: number; // token1/token0 ratio
    token0Symbol: string;
    token1Symbol: string;
    effectivePriceRange: {
        lower: number;
        upper: number;
    };
    token0USD: number;
    token1USD: number;
    totalUSD: number;
    token0Percentage: number;
    token1Percentage: number;
}

// Extended interface with APR prediction
interface TokenRatioResultWithAPR extends TokenRatioResult {
    estimatedAPR: number;
    aprConfidenceLevel: 'low' | 'medium' | 'high';
}

interface CreatePositionProps {
    position: UniswapPosition;
}

/**
 * Predicts APR based on position parameters
 */
function predictPositionAPR(
    position: UniswapPosition,
    lowerPrice: number,
    upperPrice: number,
    currentPrice: number
): { apr: number, confidenceLevel: 'low' | 'medium' | 'high' } {
    // Base APR calculation with multiple factors
    const calculateBaseAPR = () => {
        // Factor 1: Range coverage
        const rangeCoverage = (upperPrice - lowerPrice) / currentPrice;
        const rangeCoverageScore = Math.min(1, rangeCoverage / 0.5); // Normalize to 0-1

        // Factor 2: Position relative to current price
        const distanceFromCurrentPrice = Math.min(
            Math.abs(lowerPrice - currentPrice) / currentPrice,
            Math.abs(upperPrice - currentPrice) / currentPrice
        );
        const priceProximityScore = 1 - distanceFromCurrentPrice;

        // Factor 3: Volatility estimate (mock - would typically come from historical data)
        const mockVolatilityFactor = 0.5; // Placeholder value

        // Combine factors with weighted calculation
        const baseAPR = (
            (rangeCoverageScore * 0.4) +
            (priceProximityScore * 0.3) +
            (mockVolatilityFactor * 0.3)
        ) * 100; // Convert to percentage

        // Determine confidence level
        let confidenceLevel: 'low' | 'medium' | 'high' = 'medium';
        if (rangeCoverageScore < 0.3) confidenceLevel = 'low';
        if (rangeCoverageScore > 0.7 && priceProximityScore > 0.7) confidenceLevel = 'high';

        return {
            apr: Math.max(0, Math.min(baseAPR, 100)), // Constrain between 0-100%
            confidenceLevel
        };
    };

    return calculateBaseAPR();
}

/**
 * Calculates token amounts using Uniswap V3 method
 */
function calculateTokenRatiosUniswapV3Exact(
    position: UniswapPosition,
    lowerPrice: number,
    upperPrice: number,
    currentPrice: number,
    investmentAmount = 100,
    token0Percentage?: number,
    token1Percentage?: number
): TokenRatioResultWithAPR {
    // Initialize token amounts
    let token0Amount = 0;
    let token1Amount = 0;
    let token0USD = 0;
    let token1USD = 0;

    // If custom percentages are provided, use them
    if (token0Percentage !== undefined && token1Percentage !== undefined) {
        // Calculate USD values based on percentages
        token0USD = (investmentAmount * token0Percentage) / 100;
        token1USD = (investmentAmount * token1Percentage) / 100;

        // Calculate token amounts
        token0Amount = token0USD / currentPrice;
        token1Amount = token1USD;
    }
    // Otherwise use position in range to calculate with Uniswap V3 formula
    else if (currentPrice < lowerPrice) {
        // Case 1: Price below range - all liquidity in token0
        token0Amount = investmentAmount / currentPrice;
        token1Amount = 0;
        token0USD = investmentAmount;
        token1USD = 0;
    } else if (currentPrice > upperPrice) {
        // Case 2: Price above range - all liquidity in token1
        token0Amount = 0;
        token1Amount = investmentAmount;
        token0USD = 0;
        token1USD = investmentAmount;
    } else {
        // Case 3: Price within range - use exact Uniswap V3 formula
        const sqrtRatioX96Current = Math.sqrt(currentPrice);
        const sqrtRatioX96Lower = Math.sqrt(lowerPrice);
        const sqrtRatioX96Upper = Math.sqrt(upperPrice);

        // Calculate liquidity L
        const deltaL = (sqrtRatioX96Upper - sqrtRatioX96Current) / (sqrtRatioX96Current * sqrtRatioX96Upper);
        const deltaR = (sqrtRatioX96Current - sqrtRatioX96Lower);

        // L = investmentAmount / (deltaL * currentPrice + deltaR)
        const L = investmentAmount / (deltaL * currentPrice + deltaR);

        // Calculate token amounts using L
        token0Amount = L * (sqrtRatioX96Upper - sqrtRatioX96Current) / (sqrtRatioX96Current * sqrtRatioX96Upper);
        token1Amount = L * (sqrtRatioX96Current - sqrtRatioX96Lower);

        // Calculate USD values
        token0USD = token0Amount * currentPrice;
        token1USD = token1Amount;
    }

    const totalUSD = token0USD + token1USD;

    // Calculate percentages
    const actualToken0Percentage = (token0USD / totalUSD) * 100;
    const actualToken1Percentage = (token1USD / totalUSD) * 100;

    // Calculate the ratio (token1/token0)
    const ratio = token0Amount > 0 ? token1Amount / token0Amount : Infinity;

    // Predict APR
    const {apr, confidenceLevel} = predictPositionAPR(
        position,
        lowerPrice,
        upperPrice,
        currentPrice
    );

    return {
        token0Amount,
        token1Amount,
        ratio,
        token0Symbol: position.token0.symbol,
        token1Symbol: position.token1.symbol,
        effectivePriceRange: {
            lower: lowerPrice,
            upper: upperPrice
        },
        token0USD,
        token1USD,
        totalUSD,
        token0Percentage: actualToken0Percentage,
        token1Percentage: actualToken1Percentage,
        estimatedAPR: apr,
        aprConfidenceLevel: confidenceLevel
    };
}

/**
 * Enhanced CreatePositionComponent with APR prediction
 */
const CreatePositionComponent: React.FC<CreatePositionProps> = ({position}) => {
    const minDistance = 10;

    // Initialize state
    const [range, setRange] = useState<number[]>([0, 0]);
    const [sliderMin, setSliderMin] = useState<number>(0);
    const [sliderMax, setSliderMax] = useState<number>(0);
    const [lowerPrice, setLowerPrice] = useState<number>(0);
    const [upperPrice, setUpperPrice] = useState<number>(0);
    const [currentPrice, setCurrentPrice] = useState<number>(0);
    const [investmentAmount, setInvestmentAmount] = useState<number>(100);
    const [token0Percentage, setToken0Percentage] = useState<number>(50);
    const [token1Percentage, setToken1Percentage] = useState<number>(50);
    const [useCustomDistribution, setUseCustomDistribution] = useState<boolean>(false);
    const [percentageRange, setPercentageRange] = useState<number>(20);  // Default to 20%
    const [sliderRangePercentage, setSliderRangePercentage] = useState<number>(20); // % for slider min/max

    // Token ratio calculation states
    const [tokenRatio, setTokenRatio] = useState<TokenRatioResultWithAPR | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Make sure currentPrice is set from position
    useEffect(() => {
        if (position && position.currentPrice) {
            setCurrentPrice(position.currentPrice.token1PerToken0);
        }
    }, [position]);

    // Format value text for slider
    function valuetext(value: number): string {
        if (currentPrice > 0) {
            const pctChange = ((value - currentPrice) / currentPrice) * 100;
            return `${formatNumber(value)} (${pctChange > 0 ? '+' : ''}${pctChange.toFixed(1)}%)`;
        }
        return `${formatNumber(value)}`;
    }

    // Update price range based on percentage selection
    const updateRangeByPercentage = (percentage: number): void => {
        setPercentageRange(percentage);
        if (currentPrice) {
            const lowerBound = currentPrice * (1 - percentage / 100);
            const upperBound = currentPrice * (1 + percentage / 100);

            setRange([lowerBound, upperBound]);
            setLowerPrice(lowerBound);
            setUpperPrice(upperBound);
        }
    };

    // Update slider min/max range based on percentage from current price
    const updateSliderRange = (percentage: number): void => {
        setSliderRangePercentage(percentage);
        if (currentPrice) {
            const x = currentPrice * (percentage / 100);
            const newMin = Math.max(0, currentPrice - x);
            const newMax = currentPrice + x;

            setSliderMin(newMin);
            setSliderMax(newMax);

            // Also update the current position range if already set
            if (lowerPrice > 0 && upperPrice > 0) {
                setRange([lowerPrice, upperPrice]);
            } else {
                // Initialize with current percentage range
                updateRangeByPercentage(percentageRange);
            }
        }
    };

    // Initialize price range based on current price and position data
    useEffect(() => {
        if (position && position.currentPrice) {
            const currentPrice = position.currentPrice.token1PerToken0;

            // Set the current price
            setCurrentPrice(currentPrice);

            // If position has a priceRange, use that to initialize
            if (position.priceRange &&
                position.priceRange.lower &&
                position.priceRange.upper &&
                position.priceRange.lower.token1PerToken0 > 0 &&
                position.priceRange.upper.token1PerToken0 > 0) {

                const lowerBound = position.priceRange.lower.token1PerToken0;
                const upperBound = position.priceRange.upper.token1PerToken0;

                // Calculate what percentage of slider we need to show these bounds
                const lowerPct = Math.abs((currentPrice - lowerBound) / currentPrice) * 100;
                const upperPct = Math.abs((upperBound - currentPrice) / currentPrice) * 100;
                const maxPct = Math.max(lowerPct, upperPct) * 1.1; // Add 10% padding

                // Set slider range to accommodate the position bounds
                setSliderRangePercentage(Math.ceil(maxPct));

                // Set slider min/max
                const sliderPadding = currentPrice * (maxPct / 100);
                setSliderMin(Math.max(0, currentPrice - sliderPadding));
                setSliderMax(currentPrice + sliderPadding);

                // Set the actual position range
                setLowerPrice(lowerBound);
                setUpperPrice(upperBound);
                setRange([lowerBound, upperBound]);

                // Calculate custom percentage for display
                const rangePct = ((upperBound - lowerBound) / 2 / currentPrice) * 100;
                setPercentageRange(Math.round(rangePct));
            } else {
                // No price range in position, initialize with defaults
                setSliderRangePercentage(20); // Default to 20%
                updateSliderRange(20);
                updateRangeByPercentage(percentageRange);
            }
        }
    }, [position]);

    // Handle token0 percentage change
    const handleToken0PercentageChange = (value: number): void => {
        const newValue = Math.max(0, Math.min(100, value));
        setToken0Percentage(newValue);
        setToken1Percentage(100 - newValue);

        // Recalculate token ratio immediately when in custom distribution mode
        if (useCustomDistribution) {
            calculateTokenRatio();
        }
    };

    // Handle token1 percentage change
    const handleToken1PercentageChange = (value: number): void => {
        const newValue = Math.max(0, Math.min(100, value));
        setToken1Percentage(newValue);
        setToken0Percentage(100 - newValue);

        // Recalculate token ratio immediately when in custom distribution mode
        if (useCustomDistribution) {
            calculateTokenRatio();
        }
    };

    // Calculate token ratio when range or investment amount changes
    useEffect(() => {
        if (position && lowerPrice > 0 && upperPrice > 0) {
            calculateTokenRatio();
        }
    }, [lowerPrice, upperPrice, investmentAmount, currentPrice, position, useCustomDistribution, token0Percentage, token1Percentage]);

    const calculateTokenRatio = (): void => {
        setLoading(true);
        setError(null);

        try {
            // Use linear calculation with optional custom percentages
            const result = calculateTokenRatiosUniswapV3Exact(
                position,
                lowerPrice,
                upperPrice,
                currentPrice,
                investmentAmount,
                useCustomDistribution ? token0Percentage : undefined,
                useCustomDistribution ? token1Percentage : undefined
            );

            setTokenRatio(result);
        } catch (err) {
            setError(`Error calculating token ratio: ${err instanceof Error ? err.message : String(err)}`);
        } finally {
            setLoading(false);
        }
    };

    // Handle range slider change
    const handleRangeChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ): void => {
        if (!Array.isArray(newValue)) {
            return;
        }

        let updatedRange = [...range];

        if (activeThumb === 0) {
            updatedRange = [Math.min(newValue[0], range[1] - minDistance), range[1]];
        } else {
            updatedRange = [range[0], Math.max(newValue[1], range[0] + minDistance)];
        }

        setRange(updatedRange);
        setLowerPrice(updatedRange[0]);
        setUpperPrice(updatedRange[1]);

        // Reset percentage range since it's now custom
        setPercentageRange(0);
    };

    // Toggle custom distribution mode
    const handleCustomDistributionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = event.target.checked;
        setUseCustomDistribution(newValue);

        // If turning off custom distribution, recalculate based on current range
        if (!newValue) {
            calculateTokenRatio();
        }
    };

    // Generate the appropriate Uniswap deep link URL
    const generateUniswapLink = (): string => {
        if (!tokenRatio) return '#';

        return `https://app.uniswap.org/#/pools/new?chain=arbitrum&token0=${position.token0.address}&token1=${position.token1.address}&fee=${position.fee}&minPrice=${lowerPrice}&maxPrice=${upperPrice}&amount0=${tokenRatio.token0Amount}&amount1=${tokenRatio.token1Amount}`;
    };

    // Handle direct input for lower and upper prices
    const handleLowerPriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = parseFloat(e.target.value);
        if (!isNaN(newValue) && newValue < upperPrice) {
            setLowerPrice(newValue);
            setRange([newValue, upperPrice]);
            setPercentageRange(0); // Custom range
        }
    };

    const handleUpperPriceChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newValue = parseFloat(e.target.value);
        if (!isNaN(newValue) && newValue > lowerPrice) {
            setUpperPrice(newValue);
            setRange([lowerPrice, newValue]);
            setPercentageRange(0); // Custom range
        }
    };

    return (
        <Container fluid style={{maxWidth: '800px'}}>
            <Row className="text-body mb-4">
                <Col sm={12} className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="mb-0">Create Uniswap V3 Position</h4>
                    <div className="fs-5">
                        Current
                        price: <b>{formatNumber(currentPrice)} {position.token1.symbol}/{position.token0.symbol}</b>
                    </div>
                </Col>

                <Col sm={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>Amount to Invest (USD):</Form.Label>
                        <Form.Control
                            type="number"
                            min="0.01"
                            step="0.01"
                            value={investmentAmount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvestmentAmount(parseFloat(e.target.value) || 100)}
                        />
                    </Form.Group>
                </Col>

                <Col sm={6} className="mb-3">
                    <Form.Group>
                        <Form.Label>Symmetric Range (%):</Form.Label>
                        <div className="d-flex gap-2">
                            <ButtonGroup>
                                {[1, 2, 5, 10, 20].map((value) => (
                                    <Button
                                        key={value}
                                        variant={percentageRange === value ? "primary" : "outline-primary"}
                                        onClick={() => updateRangeByPercentage(value)}
                                    >
                                        {value}%
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>
                    </Form.Group>
                </Col>

                <Col sm={12} className="mb-3">
                    <Form.Group>
                        <Form.Label>Slider Range Width (from current price):</Form.Label>
                        <div className="d-flex gap-2">
                            <ButtonGroup>
                                {[5, 10, 20, 50].map((value) => (
                                    <Button
                                        key={value}
                                        variant={sliderRangePercentage === value ? "primary" : "outline-primary"}
                                        onClick={() => updateSliderRange(value)}
                                    >
                                        {value}%
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>
                        <Form.Text className="text-muted">
                            Controls how wide the slider range is (± % from current price)
                        </Form.Text>
                    </Form.Group>
                </Col>
            </Row>


            <Row className="mb-4">
                <CardBody className="text-body border rounded p-4">
                    <div className="mb-3">
                        <div className="d-flex justify-content-start align-items-center mb-2">
                            <div className="d-flex gap-2 ">
                                <Form.Group className="mb-3">
                                    <Form.Label>Min Price:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={lowerPrice}
                                        onChange={handleLowerPriceChange}
                                        size="sm"
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Max Price:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={upperPrice}
                                        onChange={handleUpperPriceChange}
                                        size="sm"
                                    />
                                </Form.Group>
                            </div>
                        </div>
                        <Box className="my-2">
                            <Slider
                                getAriaLabel={() => 'Price Range'}
                                value={range}
                                onChange={handleRangeChange}
                                min={sliderMin}
                                max={sliderMax}
                                valueLabelDisplay="auto"
                                getAriaValueText={valuetext}
                                valueLabelFormat={(value: number) => {
                                    if (currentPrice > 0) {
                                        const pctChange = ((value - currentPrice) / currentPrice) * 100;
                                        return `${formatNumber(value)} (${pctChange > 0 ? '+' : ''}${pctChange.toFixed(1)}%)`;
                                    }
                                    return `${formatNumber(value)}`;
                                }}
                                marks={(() => {
                                    const marks = [];

                                    marks.push({
                                        value: currentPrice,
                                        label: <span className="text-body fw-bold">{`Current`}</span>
                                    });

                                    const percentages = [1, 2, 5, 10, 15, 20, 25, 30, 40, 50];
                                    percentages.forEach(pct => {
                                        if (pct <= sliderRangePercentage) {
                                            const negValue = currentPrice * (1 - pct / 100);
                                            if (negValue >= sliderMin) {
                                                marks.push({
                                                    value: negValue,
                                                    label: <span className="text-body small">{`-${pct}%`}</span>
                                                });
                                            }

                                            const posValue = currentPrice * (1 + pct / 100);
                                            if (posValue <= sliderMax) {
                                                marks.push({
                                                    value: posValue,
                                                    label: <span className="text-body small">{`+${pct}%`}</span>
                                                });
                                            }
                                        }
                                    });

                                    if (lowerPrice !== currentPrice && !marks.some(mark => mark.value === lowerPrice)) {
                                        marks.push({
                                            value: lowerPrice,
                                            label: <span className="text-primary small fw-bold">min</span>
                                        });
                                    }

                                    if (upperPrice !== currentPrice && !marks.some(mark => mark.value === upperPrice)) {
                                        marks.push({
                                            value: upperPrice,
                                            label: <span className="text-primary small fw-bold">max</span>
                                        });
                                    }

                                    return marks;
                                })()}
                                sx={{
                                    '& .MuiSlider-markActive, & .MuiSlider-mark': {
                                        width: 6,
                                        height: 6,
                                        borderRadius: '50%',
                                        backgroundColor: '#8c8c8c',
                                    },
                                    '& .MuiSlider-mark[data-index="0"]': {
                                        width: 12,
                                        height: 12,
                                        backgroundColor: '#FFCA2C',
                                    },
                                    '& .MuiSlider-markLabel': {
                                        fontSize: '0.7rem',
                                        marginTop: '5px'
                                    }
                                }}
                                disableSwap
                            />

                            <div className="d-flex justify-content-between mt-2 text-body">
                                <div>
                                    Min: {formatNumber(range[0])}
                                    {currentPrice > 0 && (
                                        <span className="text-muted ms-1">
                                        ({((range[0] - currentPrice) / currentPrice * 100).toFixed(1)}%)
                                    </span>
                                    )}
                                </div>
                                <div>Current: {formatNumber(currentPrice)}</div>
                                <div>
                                    Max: {formatNumber(range[1])}
                                    {currentPrice > 0 && (
                                        <span className="text-muted ms-1">
                                        (+{((range[1] - currentPrice) / currentPrice * 100).toFixed(1)}%)
                                    </span>
                                    )}
                                </div>
                            </div>
                        </Box>
                    </div>

                    <div className="mt-4">
                        <div className="d-flex align-items-center mb-3">
                            <input
                                type="checkbox"
                                id="customDistribution"
                                className="me-2"
                                checked={useCustomDistribution}
                                onChange={handleCustomDistributionChange}
                            />
                            <label htmlFor="customDistribution" className="text-body mb-0 fw-bold">
                                Use custom token distribution
                            </label>
                        </div>

                        {useCustomDistribution && (
                            <Row className="g-3">
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>{position.token0.symbol} Allocation (%):</Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                max="100"
                                                value={token0Percentage}
                                                onChange={(e) => handleToken0PercentageChange(parseFloat(e.target.value))}
                                            />
                                            <div className="ms-2 fw-bold">{position.token0.symbol}</div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group>
                                        <Form.Label>{position.token1.symbol} Allocation (%):</Form.Label>
                                        <div className="d-flex align-items-center">
                                            <Form.Control
                                                type="number"
                                                min="0"
                                                max="100"
                                                value={token1Percentage}
                                                onChange={(e) => handleToken1PercentageChange(parseFloat(e.target.value))}
                                            />
                                            <div className="ms-2 fw-bold">{position.token1.symbol}</div>
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col sm={12} className="mt-2">
                                    <div className="text-muted small">
                                        <i>Note: In custom distribution mode, token allocation overrides the position
                                            range settings.</i>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </CardBody>
            </Row>


            {tokenRatio && !loading && (
                <Row className="mb-4">
                    <CardBody className="text-body border rounded p-4 d-flex flex-column align-items-center">
                        <Col sm={12} className="text-center">
                            <div className="d-flex justify-content-center align-items-center gap-2 mb-2">
                                <h6 className="mb-0">Estimated Position APR</h6>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="apr-tooltip">
                            <span>
                                APR is estimated based on position range, current price proximity, and token volatility. Actual returns may vary.
                            </span>
                                        </Tooltip>
                                    }
                                >
                    <span className="d-inline-block">
                        <BsInfoCircle style={{cursor: 'help'}}/>
                    </span>
                                </OverlayTrigger>
                            </div>
                            <div className="d-flex justify-content-center align-items-center gap-2 mb-2">
                <span className={`badge ${
                    tokenRatio.aprConfidenceLevel === 'high' ? 'bg-success' :
                        tokenRatio.aprConfidenceLevel === 'medium' ? 'bg-warning' : 'bg-danger'
                }`}>
                    {tokenRatio.aprConfidenceLevel.toUpperCase()} CONFIDENCE
                </span>
                                <strong>{formatNumber(tokenRatio.estimatedAPR, 2)}%</strong>
                            </div>
                            <div className="text-muted small text-center">
                                {tokenRatio.aprConfidenceLevel === 'high' ?
                                    "Your position looks well-optimized for current market conditions." :
                                    tokenRatio.aprConfidenceLevel === 'medium' ?
                                        "Your position has moderate optimization potential." :
                                        "Consider adjusting your position range for better returns."}
                            </div>
                        </Col>
                    </CardBody>
                </Row>
            )}


            {/* Token Ratio Display Section */}
            <Row className="mb-4">
                <CardBody className="text-body border rounded p-4">
                    <div className="mb-1 d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Token Ratio for Position</h5>
                        {tokenRatio && !loading && (

                            <a href={generateUniswapLink()}
                               target="_blank"
                               rel="noopener noreferrer"
                               className="btn btn-primary"
                            >
                                Create Position
                            </a>
                        )}
                    </div>

                    {loading && (
                        <div className="text-center my-3">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p className="mt-2">Calculating token ratio...</p>
                        </div>
                    )}

                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}

                    {tokenRatio && !loading && (
                        <div>
                            <Row className="g-4">
                                <Col sm={12}>
                                    <div className="mb-1">
                                        <strong>{tokenRatio.token0Symbol}:</strong> {formatNumber(tokenRatio.token0Amount, 6)}
                                        {tokenRatio.token0USD > 0 && ` (≈$${formatNumber(tokenRatio.token0USD, 2)})`}
                                    </div>
                                    <div className="mb-1">
                                        <strong>{tokenRatio.token1Symbol}:</strong> {formatNumber(tokenRatio.token1Amount, 2)}
                                        {tokenRatio.token1USD > 0 && ` (≈$${formatNumber(tokenRatio.token1USD, 2)})`}
                                    </div>
                                    <div className="mb-1">
                                        <strong>Total USD Value:</strong> ${formatNumber(tokenRatio.totalUSD, 2)}
                                    </div>
                                </Col>


                                {/* Progress bar showing the token distribution */}
                                <Col sm={12} className="mt-4">
                                    <h6 className="mb-2 mx-auto text-center text-muted small">Token Distribution</h6>
                                    <div className="progress mt-2" style={{height: '30px'}}>
                                        <div
                                            className="progress-bar bg-primary"
                                            role="progressbar"
                                            style={{width: `${tokenRatio.token0Percentage}%`}}
                                            aria-valuenow={tokenRatio.token0Percentage}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            {tokenRatio.token0Percentage > 10 ?
                                                `${formatNumber(tokenRatio.token0Percentage, 1)}% ${tokenRatio.token0Symbol}` :
                                                ''
                                            }
                                        </div>
                                        <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                            style={{width: `${tokenRatio.token1Percentage}%`}}
                                            aria-valuenow={tokenRatio.token1Percentage}
                                            aria-valuemin={0}
                                            aria-valuemax={100}
                                        >
                                            {tokenRatio.token1Percentage > 10 ?
                                                `${formatNumber(tokenRatio.token1Percentage, 1)}% ${tokenRatio.token1Symbol}` :
                                                ''
                                            }
                                        </div>
                                    </div>

                                </Col>

                                {/* Position Status Indicator */}
                                <Col sm={12} className="mt-3">
                                    <div className="d-flex align-items-center gap-2 mb-2">
                                        <FaCircle
                                            size={12}
                                            color={
                                                currentPrice < tokenRatio.effectivePriceRange.lower ? "#dc3545" :
                                                    currentPrice > tokenRatio.effectivePriceRange.upper ? "#dc3545" : "#28a745"
                                            }
                                        />
                                        <span>
                                        {currentPrice < tokenRatio.effectivePriceRange.lower ?
                                            `Price below range (all liquidity in ${tokenRatio.token0Symbol})` :
                                            currentPrice > tokenRatio.effectivePriceRange.upper ?
                                                `Price above range (all liquidity in ${tokenRatio.token1Symbol})` :
                                                "Price in range (earning fees)"}
                                    </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                </CardBody>
            </Row>
        </Container>
    );

}


export default CreatePositionComponent;